<template>
	<v-container fluid class="pa-0" v-if="permisoVista('mantenimientopreventivo-calendario','r')">
		<v-dialog
			v-model="dialogReporteTiempoReal"
			persistent
			max-width="700px"
		>
			<v-card>
				<v-card-title>Reporte en Tiempo Real</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<HeaderInfoMantenimiento
									:maquinas="maquinas"
									:items="[{...editedItem}]"
									:autodetectCategoriaMaquina="false"
									:headers="headersDetallesItemsFiltred"
								/>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsable"
									label="Responsable"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									:disabled="reportingReal"
									:error-messages="erroresItem.errorResponsable"
									@focus="$set(erroresItem, 'errorResponsable', null)"
								></v-autocomplete>
							</v-col>
							<AsignacionMantenimiento
								:tipoAsignacion.sync="editedItem.tipoAsignacion"
								:mecanicos.sync="editedItem.mecanicos"
								:idMantenimientoProveedor.sync="editedItem.idMantenimientoProveedor"
								:error-mecanicos.sync="erroresItem.errorMecanicos"
								:error-proveedor.sync="erroresItem.errorProveedor"
								:disabled="reportingReal"
								:items="{personas, proveedores}"
							/>
						</v-row>
						<v-row v-if="!!editedItem.errorConflicto">
							<v-col cols="12">
								<p class="mb-0 red--text">
									{{ editedItem.errorConflicto }}
								</p>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="reportingReal"
						@click="cerrarDialogReportes"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reportingReal"
						:loading="reportingReal"
						@click="reportarTiempoReal"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogReporteExtemporaneo"
			persistent
			max-width="700px"
		>
			<v-card>
				<v-card-title>Reporte Extemporáneo</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<HeaderInfoMantenimiento
									:maquinas="maquinas"
									:items="[{...editedItem}]"
									:autodetectCategoriaMaquina="false"
									:headers="headersDetallesItemsFiltred"
								/>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsable"
									label="Responsable"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									:disabled="reportingExtemporaneo"
									:error-messages="erroresItem.errorResponsable"
									@focus="$set(erroresItem, 'errorResponsable', null)"
								></v-autocomplete>
							</v-col>
							<AsignacionMantenimiento
								:tipoAsignacion.sync="editedItem.tipoAsignacion"
								:mecanicos.sync="editedItem.mecanicos"
								:idMantenimientoProveedor.sync="editedItem.idMantenimientoProveedor"
								:error-mecanicos.sync="erroresItem.errorMecanicos"
								:error-proveedor.sync="erroresItem.errorProveedor"
								:disabled="reportingExtemporaneo"
								:items="{personas, proveedores}"
							/>
							<template v-if="esIndasa">
								<v-col v-if="editedItem.tipo != 3" :cols="computeColImporteNeto">
									<v-text-field
										v-model="editedItem.importeNeto"
										label="Importe Neto"
										type="number"
										hide-spin-buttons
										prefix="$"
										clearable
										:disabled="reportingExtemporaneo"
										:error-messages="erroresItem.errorImporteNeto"
										@focus="erroresItem.errorImporteNeto = null"
									></v-text-field>
								</v-col>
								<template v-if="editedItem.tipo != 4">
									<v-col
										v-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'transporte',editedItem.idMaquina)"
										:cols="esIndasa && editedItem.tipo != 3 ? 6 : 12"
									>
										<KilometrajeTextfield
											v-if="dialogReporteExtemporaneo"
											v-model="editedItem.kilometraje"
											extemporaneo
											:idMaquinaInstancia="editedItem.idMaquinaInstancia"
											:kilometrajeMin.sync="editedItem.kilometrajeMin"
											:error-messages.sync="erroresItem.errorKilometraje"
											:loadingKilometrajeMin.sync="loadingKilometrajeMin"
											:disabled="reportingExtemporaneo"
										/>
									</v-col>
									<v-col
										v-else-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'montacargas',editedItem.idMaquina)"
										:cols="esIndasa && editedItem.tipo != 3 ? 6 : 12"
									>
										<v-text-field
											v-model="editedItem.horasTrabajadas"
											label="Horas Trabajadas"
											type="number"
											hide-spin-buttons
											clearable
											:disabled="reportingExtemporaneo"
											:error-messages="erroresItem.errorHorasTrabajadas"
											@focus="erroresItem.errorHorasTrabajadas = null"
										></v-text-field>
									</v-col>
								</template>
							</template>
							<v-col cols="12">
								<VueCtkDateTimePicker
									v-model="editedItem.fechaInicio"
									label="Inicio"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="erroresItem.errorFechaInicio != null"
									@input="erroresItem.errorFechaInicio = null"
								/>
								<v-slide-y-transition>
									<p
										v-if="erroresItem.errorFechaInicio"
										class="red--text pt-1 mb-0"
										style="font-size: 12px !important"
									>
										{{ erroresItem.errorFechaInicio }}
									</p>
								</v-slide-y-transition>
							</v-col>
							<v-col cols="12">
								<VueCtkDateTimePicker
									v-model="editedItem.fechaFin"
									:min-date="editedItem.fechaInicio"
									label="Termino"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="erroresItem.errorFechaFin != null"
									@input="erroresItem.errorFechaFin = null"
								/>
								<v-slide-y-transition>
									<p
										v-if="erroresItem.errorFechaFin"
										class="red--text pt-1 mb-0"
										style="font-size: 12px !important"
									>
										{{ erroresItem.errorFechaFin }}
									</p>
								</v-slide-y-transition>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="pa-0">
								<Comprobaciones
									v-if="dialogReporteExtemporaneo"
									ref="checklist"
									:disabled="reportingExtemporaneo"
									:idMantenimientoServicio="editedItem.idMantenimientoServicio"
									:idMantenimientoTarea="editedItem.idMantenimientoTarea"
									:idMantenimientoInspeccion="editedItem.idMantenimientoInspeccion"
									:idMantenimientoCalendarioInspeccion="editedItem.idMantenimientoCalendarioInspeccion"
									:idMaquina="editedItem.idMaquina"
									:tipoReporte="editedItem.tipo"
									:loadingComprobaciones.sync="loadingComprobaciones"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-autocomplete-textarea
									v-if="dialogReporteExtemporaneo"
									v-model="editedItem.recomendacion"
									label="Descripción o Recomendación"
									outlined
									auto-grow
									rows="1"
									:items="recomendaciones"
									:loading="loadingRecomendaciones"
									:disabled="reportingExtemporaneo"
									:error-message="erroresItem.errorRecomendacion"
									@click:refresh="getRecomendaciones"
									@update:error-message="erroresItem.errorRecomendacion = $event"
								></v-autocomplete-textarea>
							</v-col>
						</v-row>
						<v-slide-y-transition>
							<v-row v-if="erroresComprobaciones.length > 0">
								<v-col cols="12">
									<ul>
										<li
											class="red--text"
											v-for="(
												error, idx
											) in erroresComprobaciones"
											:key="'error-' + idx"
										>
											{{ error }}
										</li>
									</ul>
								</v-col>
							</v-row>
						</v-slide-y-transition>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="reportingExtemporaneo || loadingComprobaciones || loadingKilometrajeMin"
						@click="cerrarDialogReportes"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reportingExtemporaneo || loadingComprobaciones || loadingKilometrajeMin"
						:loading="reportingExtemporaneo"
						@click="reportarExtemporaneo"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalles" persistent max-width="80%">
			<v-card>
				<v-card-title
					>{{ detallesItem.idMaquinaInstancia == -1 ? 'Servicios de Mantenimiento' : `Actividades de ${nombreMaquina(detallesItem)}` }} del día
					{{ detallesItem.fecha }}</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-data-table
								:items="detallesItem.detalles"
								:headers="headersDetallesItems"
								:items-per-page="-1"
								hide-default-footer
							>
								<template v-slot:[`item.tipo`]="{ item }">
									{{ $utils.mantenimientos.getTipoMantenimiento(item) }}
								</template>
								<template v-slot:[`item.estado`]="{ item }">
									{{ textoEstadoDetalle(item.estado) }}
								</template>
								<template v-slot:[`item.nombre`]="{ item }">
									{{ getNombreMantenimiento(item) }}
								</template>
								<template v-slot:[`item.actions`]="{ item }">
									<div
										v-if="item.estado == 1 && permisoVista('mantenimientopreventivo-calendario','u')"
										class="
											d-flex
											justify-center
											align-center
										"
									>
										<v-btn
											text
											color="error"
											@click="
												onClickReporteExtemporaneo(item)
											"
											>Reporte<br />Extemporáneo</v-btn
										>
										<v-btn
											text
											color="primary"
											@click="
												onClickReporteTiempoReal(item)
											"
											>Reporte<br />Tiempo Real</v-btn
										>
										<v-btn
											v-if="showBtnReprogramar"
											text
											color="warning"
											@click="onClickReprogramar(item)"
											>Reprogramar</v-btn
										>
									</div>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialog()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogReprogramar" persistent max-width="40%">
			<v-card>
				<v-card-title>Reprogramar actividad</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<HeaderInfoMantenimiento
									:maquinas="maquinas"
									:items="[{...editedItem}]"
									:autodetectCategoriaMaquina="false"
									:headers="headersDetallesItemsFiltred"
								/>
							</v-col>
							<v-col cols="12">
								<MenuDatepicker
									v-model="editedItem.fechaReprogramar"
									label="Nueva fecha"
									:min-date="minDateReprogramar"
									clearable
									:disabled="reprogramando"
									:error-message="erroresItem.errorFechaReprogramar"
									@focus="$set(erroresItem,'errorFechaReprogramar',null)"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						text
						color="success"
						:disabled="reprogramando"
						@click="cerrarDialogReportes"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reprogramando"
						:loading="reprogramando"
						@click="reprogramarActividad()"
						>Reprogramar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="3">
				<MenuDatepicker
					v-model="filtros.fechaInicio"
					label="[Obligatorio] Fecha de Inicio"
					:max-date="filtros.fechaFin"
					clearable
					hide-details
					@input="getDatos()"
					:disabled="loading"
				/>
			</v-col>
			<v-col cols="3">
				<MenuDatepicker
					v-model="filtros.fechaFin"
					label="[Obligatorio] Fecha de Fin"
					clearable
					hide-details
					:min-date="filtros.fechaInicio"
					@input="getDatos()"
					:disabled="loading"
				/>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.tipoMantenimiento"
					label="[Opcional] Tipo de Mantenimiento"
					hide-details
					clearable
					:items="tiposMantenimientosComputed"
					:disabled="loading"
					@change="getDatos()"
				></v-autocomplete>
			</v-col>
			<v-col v-if="!esIndasa" cols="3">
				<v-autocomplete
					v-model="filtros.idSubdepartamento"
					label="[Opcional] Subdepartamento"
					clearable
					:items="subdepartamentos"
					:item-text="nombreSubdepto"
					item-value="idSubdepartamento"
					hide-details
					:disabled="loading || loadingSubdeptos"
					:loading="loadingSubdeptos"
					@change="getDatos"
				></v-autocomplete>
			</v-col>
			<v-col v-if="esIndasa" cols="3" class="pt-4">
				<v-autocomplete
					ref="ubicacionesSelector"
					v-model="filtros.idsUbicaciones"
					label="[Opcional] Ubicaciones"
					hide-details
					clearable
					:items="ubicaciones"
					item-value="idUbicacion"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingUbicaciones"
					:loading="loadingUbicaciones"
					@change="onChangeUbicaciones"
				></v-autocomplete>
			</v-col>
			<v-col v-if="!esIndasa" cols="3">
				<v-autocomplete
					ref="areasSelector"
					v-model="filtros.idsAreas"
					label="[Opcional] Áreas"
					hide-details
					clearable
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingAreas"
					:loading="loadingAreas"
					@change="onChangeAreas"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					ref="modelosSelector"
					v-model="filtros.idsMaquinas"
					label="[Opcional] Modelos"
					hide-details
					clearable
					:items="maquinas"
					item-value="idMaquina"
					:item-text="maquinaNombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingMaquinas"
					:loading="loadingMaquinas"
					@change="onChangeModelos"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					ref="categoriasMaquinasSelector"
					v-model="filtros.idsCategorias"
					label="[Opcional] Categorías de máquinas"
					hide-details
					clearable
					:items="categorias"
					item-value="idMaquinaCategoria"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingCategorias"
					:loading="loadingCategorias"
					@change="onChangeCategoriaMaquinas"
				></v-autocomplete>
			</v-col>
			<v-col v-if="esIndasa" cols="3" class="pt-2">
				<v-select
					v-model="filtros.idTipoUnidad"
					label="[Opcional] Tipo de Unidad"
					hide-details
					clearable
					:items="$utils.mantenimientos.tiposTransporte"
					:disabled="loading || !esFiltroTransporte"
					@change="getDatos()"
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="py-2">
				<download-btn
					color="blue darken-2"
					text
					@click="descargarReporte"
					label="Descargar Calendario<br/>de Mantenimiento"
					:disabled="escenarioInvalido"
				></download-btn>
			</v-col>
		</v-row>
		<div
			v-if="escenarioInvalido"
			class="preview-container d-flex justify-center"
		>
			<div
				v-if="filtros.fechaInicio == null || filtros.fechaFin == null"
				class="align-self-center text-center"
			>
				<v-icon size="100" color="grey">mdi-calendar-clock</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					Seleccione las fechas
				</p>
			</div>
			<div v-else-if="loading" class="align-self-center text-center">
				<v-progress-circular
					indeterminate
					size="100"
					width="7"
					color="blue darken-2"
				></v-progress-circular>
				<p class="text-h4">Cargando</p>
			</div>
			<div
				v-else-if="maquinasInstancias.length == 0"
				class="align-self-center text-center"
			>
				<v-icon size="100" color="grey">mdi-calendar-clock</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					No hay datos disponibles
				</p>
			</div>
		</div>
		<v-row v-else>
			<v-col cols="12" class="pt-0">
				<v-simple-table class="custom-max-header" fixed-header>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									class="text-center text--secondary"
									style="
										font-size: 1.0625rem !important;
										width: 350px
									"
								>
									Máquina
								</th>
								<th
									v-for="(header, index) in headerFechas"
									:key="`header-${index}`"
									class="
										text-center text--secondary
										py-2
										text-th
									"
									:class="customBackgroundColor(header)"
								>
									<span class="vertical-text">{{
										header
									}}</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(
									maquina, indexMaquina
								) in maquinasInstancias"
								:key="maquina.idMaquinaInstancia"
								class="text-center"
							>
								<td>
									{{ nombreMaquina(maquina) }}
								</td>
								<td
									v-for="(
										operacion, indexOperacion
									) in maquina.operaciones"
									:key="`operacion-${indexMaquina}-${indexOperacion}`"
									:class="
										customBackgroundColor(operacion.fecha)
									"
								>
									<v-tooltip
										v-if="operacion.estado > 0"
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												size="30"
												:color="
													colorEstado(
														operacion.estado
													)
												"
												@click="
													verDetalles(
														maquina,
														operacion
													)
												"
											>
												mdi-cog
											</v-icon>
										</template>
										<span class="white--text">
											Estado: {{ textoEstado(operacion.estado) }}<br>
											Ver Detalles
										</span>
									</v-tooltip>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import qs from "qs";
import Comprobaciones from "./Comprobaciones.vue";
import HeaderInfoMantenimiento from './Catalogos/HeaderInfoMantenimiento.vue';
import AsignacionMantenimiento from './AsignacionMantenimiento.vue';
import MenuDatepicker from '../../MenuDatepicker.vue';
import KilometrajeTextfield from './KilometrajeTextfield.vue';

export default {
	components: { Comprobaciones, HeaderInfoMantenimiento, AsignacionMantenimiento, MenuDatepicker, KilometrajeTextfield },
	data() {
		return {
			reprogramando: false,
			dialogReprogramar: false,
			loadingUbicaciones: false,
			ubicaciones: [],
			loadingKilometrajeMin: false,
			loadingComprobaciones: false,
			loadingRecomendaciones: false,
			recomendaciones: [],
			proveedores: [],
			erroresItem: {
				errorResponsable: null,
				errorFechaInicio: null,
				errorFechaFin: null,
				errorImporteNeto: null,
				errorKilometraje: null,
				errorHorasTrabajadas: null,
				errorRecomendacion: null,
				errorMecanicos: null,
				errorProveedor: null,
				errorFechaReprogramar: null
			},
			dialogReporteTiempoReal: false,
			reportingReal: false,
			reportingExtemporaneo: false,
			erroresComprobaciones: [],
			compExtKey: 0,
			personas: [],
			dialogReporteExtemporaneo: false,
			editedItem: {},
			loadingCategorias: false,
			categorias: [],
			maquinas: [],
			loadingMaquinas: false,
			loadingAreas: false,
			areas: [],
			dialogDetalles: false,
			headersDetallesItems: [
				{
					text: "Tipo",
					align: "center",
					value: "tipo",
				},
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Folio",
					align: "center",
					value: "folio",
				},
				{
					text: "Nombre",
					align: "center",
					value: "nombre",
				},
				{
					text: "Categoría Mantenimiento",
					align: "center",
					sortable: false,
					value: "categoriaMantenimiento",
				},
				{
					text: "Categoría Máquina",
					align: "center",
					sortable: false,
					value: "categoriaMaquina",
				},
				{
					text: "Máquina",
					align: "center",
					value: "maquina",
				},
				{
					text: "Fecha Inicio",
					align: "center",
					value: "inicio",
				},
				{
					text: "Duración",
					align: "center",
					value: "duracion",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			detallesItem: {
				idMaquinaInstancia: null,
				marca: null,
				modelo: null,
				codigo: null,
				fecha: null,
				detalles: [],
			},
			defaultDetallesItem: {
				idMaquinaInstancia: null,
				marca: null,
				modelo: null,
				codigo: null,
				fecha: null,
				detalles: [],
			},
			subdepartamentos: [],
			loadingSubdeptos: false,
			loading: false,
			filtros: {
				idsUbicaciones: [],
				idsAreas: [],
				idsMaquinas: [],
				idsCategorias: [],
				fechaInicio: null,
				fechaFin: null,
				idSubdepartamento: null,
				idTipoUnidad: null,
				tipoMantenimiento: null,
			},
			headerFechas: [],
			maquinasInstancias: [],
		};
	},
	beforeMount(){
		this.precargarFechas();
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(["token","nombreEmpresa","moldes"]),
		tiposMantenimientosComputed(){
			return this.$utils.mantenimientos.tiposMantenimiento.filter(t => !this.moldes ? t.value < 5 : true);
		},
		minDateReprogramar(){
			const fechaActual = new Date(new Date().setHours(0, 0, 0, 0));
			return fechaActual.toISOString().substring(0, 10);
		},
		showBtnReprogramar(){
			if(!this.fechaActividadISO) return false;
			const fechaActual = new Date(new Date().setHours(0, 0, 0, 0));
			return this.fechaActividadISO >= fechaActual;
		},
		fechaActividadISO(){
			if(!this.detallesItem.fecha) return null;
			const splitedFecha = this.detallesItem.fecha.split("/");
			const dia = parseInt(splitedFecha[0], 10);
			// Restamos 1 al mes ya que en JavaScript los meses van de 0 a 11
			const mes = parseInt(splitedFecha[1], 10) - 1;
			const anio = parseInt(splitedFecha[2], 10);
			return new Date(anio, mes, dia);
		},
		esFiltroTransporte(){
			const categoria = this.categorias.find(cat => cat.nombre == "TRANSPORTES");
			return categoria && this.filtros.idsCategorias.length == 1
				? this.filtros.idsCategorias.includes(categoria.idMaquinaCategoria)
				: false;
		},
		computeColImporteNeto(){
			return (
				this.editedItem.tipo == 4 ||
				this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'basculas',this.editedItem.idMaquina)
			)
				? 12
				: 6;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		headersDetallesItemsFiltred(){
			return this.headersDetallesItems.filter( 
				header => header.value != "estado" && header.value != "actions"
			);
		},
		diaActual() {
			return moment().format("DD/MM/YYYY");
		},
		escenarioInvalido() {
			return (
				this.filtros.fechaInicio == null ||
				this.filtros.fechaFin == null ||
				this.loading ||
				this.maquinasInstancias.length == 0
			);
		},
	},
	methods: {
		validateReprogramarActividad(){
			this.resetErrores();

			if(!this.editedItem.fechaReprogramar)
				this.erroresItem.errorFechaReprogramar = "Requerido";

			return Object.values(this.erroresItem).every( (value) => value == null );
		},
		reprogramarActividad(){
			if(!this.validateReprogramarActividad()) return;

			this.reprogramando = true;

			axios
				.post('/Mantenimientos/ReprogramarActividad',{
					idMantenimientoTarea: this.editedItem.idMantenimientoTarea,
					idMantenimientoCalendarioInspeccion: this.editedItem.idMantenimientoCalendarioInspeccion,
					fechaReprogramar: this.editedItem.fechaReprogramar
				})
				.then( () => {
					this.reprogramando = false;
					this.cerrarDialogReportes();
					this.cerrarDialog();
					this.getDatos();
					this.getRecomendaciones();
				})
				.catch(error => {
					this.reprogramando = false;
					console.log(error);
				});
		},
		onClickReprogramar(item){
			this.editedItem = structuredClone(item);
			this.dialogReprogramar = true;
		},
		onChangeAreas(){
			this.$refs.areasSelector.blur();
			this.getDatos();
		},
		onChangeModelos(){
			this.$refs.modelosSelector.blur();
			this.getDatos();
		},
		onChangeCategoriaMaquinas(){
			this.$refs.categoriasMaquinasSelector.blur();
			if(!this.esFiltroTransporte) this.filtros.idTipoUnidad = null;
			this.getDatos();
		},
		onChangeUbicaciones(){
			this.$refs.ubicacionesSelector.blur();
			this.getDatos();
		},
		precargarFechas(){
			moment.locale('es');
			const currentDate = moment();
			const weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD');;
			const weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD');;
			this.$set(this.filtros,'fechaInicio',weekStart);
			this.$set(this.filtros,'fechaFin',weekEnd);
		},
		resetErrores() {
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
			this.erroresComprobaciones = [];
		},
		validateTipoAsignacion(){
			if(this.editedItem.tipoAsignacion == 1){
				if (
					this.editedItem.mecanicos == null ||
					this.editedItem.mecanicos?.length == 0
				) {
					this.$set(this.erroresItem, "errorMecanicos", "Requerido al menos uno");
				}
			}
			else {
				if(this.editedItem.idMantenimientoProveedor == null){
					this.$set(this.erroresItem, "errorProveedor", "Requerido");
				}
			}
		},
		validateReal() {
			this.resetErrores();

			if (this.editedItem.responsable == null) {
				this.$set(this.erroresItem, "errorResponsable", "Requerido");
			}

			this.validateTipoAsignacion();

			const hayErrores = Object.values(this.erroresItem).some(value => value != null);

			return !hayErrores;
		},
		reportarTiempoReal() {
			if (!this.validateReal()) return;

			this.reportingReal = true;

			const data = {
				idMantenimientoCalendarioInspeccion:
					this.editedItem.idMantenimientoCalendarioInspeccion,
				idMantenimientoTarea: this.editedItem.idMantenimientoTarea,
				idResponsable: this.editedItem.responsable,
				responsable: this.editedItem.responsable,
				mecanicos: this.editedItem.mecanicos,
				tipoAsignacion: this.editedItem.tipoAsignacion,
				idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor
			};

			axios({
				url: this.editedItem.tipo == 3
						? "/Mantenimientos/IniciarInspeccion"
						: "/Mantenimientos/IniciarTareaMantenimientoPreventivo",
				method: this.editedItem.tipo == 3 ? "PUT" : "POST",
				data,
			})
				.then(() => {
					this.reportingReal = false;
					this.cerrarDialogReportes();
					this.cerrarDialog();
					this.getDatos();
				})
				.catch((error) => {
					this.reportingReal = false;
					console.log(error);
					if (error.response?.status == 409) {
						this.$set(this.editedItem, 'errorConflicto', "Esta actividad ya está siendo reportada en tiempo real, espere a que finalice para iniciar una nueva");
					}
				});
		},
		validateExtemporaneo() {
			this.resetErrores();

			if (this.editedItem.responsable == null) {
				this.$set(this.erroresItem, "errorResponsable", "Requerido");
			}

			this.validateTipoAsignacion();

			if (this.editedItem.fechaInicio == null) {
				this.$set(this.erroresItem, "errorFechaInicio", "Requerido");
			}

			if (this.editedItem.fechaFin == null) {
				this.$set(this.erroresItem, "errorFechaFin", "Requerido");
			}

			if(this.esIndasa){
				if(this.editedItem.tipo != 3 && this.editedItem.importeNeto != null){
					const parsedImporteNeto = parseFloat(this.editedItem.importeNeto);
					if(isNaN(parsedImporteNeto)) this.erroresItem.errorImporteNeto = "Invalido";
					else if (parsedImporteNeto < 0) this.erroresItem.errorImporteNeto = "Debe ser mayor o igual a 0";
				}
	
				if (this.editedItem.tipo != 4) {

					if (this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.editedItem.idMaquina)) {
						this.erroresItem.errorKilometraje = this.$utils.mantenimientos.validateKilometraje(this.editedItem,true);
					}
		
					if(this.editedItem.horasTrabajadas != null){
						const parsedHorasTrabajadas = parseFloat(this.editedItem.horasTrabajadas);
						if(isNaN(parsedHorasTrabajadas)) this.erroresItem.errorHorasTrabajadas = "Invalido";
						else if (parsedHorasTrabajadas < 0) this.erroresItem.errorHorasTrabajadas = "Debe ser mayor o igual a 0";
					}
				}

				if(!this.editedItem.recomendacion) this.erroresItem.errorRecomendacion = "Requerido"
			}
			
			const comprobacionesValidas = this.$refs.checklist.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.erroresComprobaciones = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		reportarExtemporaneo() {
			if (!this.validateExtemporaneo()) return;

			const item = this.$refs.checklist.terminarTarea();
			this.reportingExtemporaneo = true;

			const payload = {
				idMantenimientoTarea: this.editedItem.idMantenimientoTarea,
				responsable: this.editedItem.responsable,
				mecanicos: this.editedItem.mecanicos,
				inicio: this.editedItem.fechaInicio,
				fin: this.editedItem.fechaFin,
				importeNeto: parseFloat(this.editedItem.importeNeto) || null,
				kilometraje: parseFloat(this.editedItem.kilometraje) || null,
				horasTrabajadas: parseFloat(this.editedItem.horasTrabajadas) || null,
				recomendacion: this.editedItem.recomendacion,
				idMantenimientoCalendarioInspeccion:
					this.editedItem.idMantenimientoCalendarioInspeccion,
				fechaInicioRealizo: this.editedItem.fechaInicio,
				fechaFinRealizo: this.editedItem.fechaFin,
				idResponsable: this.editedItem.responsable,
				comprobaciones: item.comprobaciones,
				tipoAsignacion: this.editedItem.tipoAsignacion,
				idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor
			};

			axios({
				url: this.editedItem.tipo == 3
						? "/Mantenimientos/InspeccionExtemporanea"
						: "/Mantenimientos/Completado",
				method: this.editedItem.tipo == 3 ? "PUT" : "POST",
				data: payload,
			})
				.then(() => {
					this.reportingExtemporaneo = false;
					this.cerrarDialogReportes();
					this.cerrarDialog();
					this.getDatos();
					this.getRecomendaciones();
				})
				.catch((error) => {
					this.reportingExtemporaneo = false;
					console.log(error);
				});
		},
		resetErrorFecha(item, key, errorKey) {
			if (item[key] != null) {
				this.$set(item, errorKey, null);
			}
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		maquinaNombre: ({ marca, modelo }) => `${marca} - ${modelo}`.trim(),
		getNombreMantenimiento: ({ codigo, nombre }) =>
			`[${codigo}] - ${nombre}`.trim(),
		nombreMaquina: ({ idMaquinaInstancia, codigo, marca, model }) => {
			return idMaquinaInstancia == -1 ? 'SERVICIO' : `[${codigo}] - ${marca} ${model}`.trim()
		},
		cerrarDialogReportes() {
			this.dialogReprogramar = false;
			this.dialogReporteExtemporaneo = false;
			this.dialogReporteTiempoReal = false;
			this.$nextTick(() => {
				this.editedItem = {};
				this.resetErrores();
			});
		},
		onClickReporteExtemporaneo(item) {
			this.editedItem = structuredClone(item);
			this.dialogReporteExtemporaneo = true;
		},
		onClickReporteTiempoReal(item) {
			this.editedItem = structuredClone(item);
			this.dialogReporteTiempoReal = true;
		},
		textoEstadoDetalle(estado){
			switch (estado) {
				case 1:
					return "Pendiente";
				case 2:
					return "En Progreso";
				case 3:
					return "Finalizado";
				default:
					return "N/D";
			}
		},
		getTextTipo(tipo) {
			switch (tipo) {
				case 1:
					return "Preventivo";
				case 2:
					return "Correctivo";
				case 3:
					return "Inspección";
				case 4:
					return "Servicio";
				default:
					return "N/A";
			}
		},
		nombreSubdepto: ({ codigo, nombre }) =>
			codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		customBackgroundColor(fecha) {
			return fecha == this.diaActual ? "today-background-color" : "";
		},
		cerrarDialog() {
			this.dialogDetalles = false;
			setTimeout(() => {
				this.detallesItem = structuredClone(this.defaultDetallesItem);
			}, 200);
		},
		verDetalles(maquina, operacion) {
			this.detallesItem.idMaquinaInstancia = maquina.idMaquinaInstancia;
			this.detallesItem.marca = maquina.marca;
			this.detallesItem.model = maquina.model;
			this.detallesItem.codigo = maquina.codigo;
			this.detallesItem.fecha = operacion.fecha;
			this.detallesItem.detalles = operacion.detalles.slice();
			this.dialogDetalles = true;
		},
		textoEstado(estado){
			switch (estado) {
				case 2:
					return "En Progreso";
				case 3:
					return "Finalizadas";
				case 4:
					return "Pendientes con retraso"
				default:
					return "Pendientes";
			}
		},
		colorEstado(estado) {
			switch (estado) {
				case 2:
					return "orange";
				case 3:
					return "success";
				case 4:
					return "red";
				default:
					return;
			}
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			axios({
				url: `/Mantenimientos/XlsxCalendarioMantenimiento`,
				params: {
					...this.filtros,
					token: this.token,
				},
				paramsSerializer: (params) =>
					qs.stringify(params, { indices: false }),
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = `calendario-mantenimientos-${datetime}.xlsx`;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		initialize() {
			if(this.esIndasa) this.getUbicaciones();
			else this.getAreas();
			this.getSubdepartamentos();
			this.getCategorias();
			this.getMaquinas();
			this.getPersonas();
			this.getProveedoresMantenimiento();
			this.getDatos();
			this.getRecomendaciones();
		},
		getUbicaciones(){
			this.loadingUbicaciones = true;

			axios
				.get('/Ubicaciones')
				.then( response => {
					this.ubicaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingUbicaciones = false;
				});
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		getProveedoresMantenimiento(){
			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch( (error) => {
					this.proveedores = [];
					console.log(error);
				});
		},
		getPersonas() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCategorias() {
			this.loadingCategorias = true;
			axios
				.get("/MaquinaCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getMaquinas() {
			this.loadingMaquinas = true;
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingMaquinas = false;
				});
		},
		getAreas() {
			this.loadingAreas = true;

			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(
						(area) => area.idArea > 1
					);
				})
				.catch(console.log)
				.finally(() => {
					this.loadingAreas = false;
				});
		},
		getSubdepartamentos() {
			this.loadingSubdeptos = true;
			axios
				.get("/Subdepartamento")
				.then((response) => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingSubdeptos = false;
				});
		},
		getDatos() {
			if (
				this.filtros.fechaInicio == null ||
				this.filtros.fechaFin == null
			)
				return;

			this.loading = true;

			axios
				.get("/Mantenimientos/CalendarioMantenimiento", {
					params: this.filtros,
					paramsSerializer: (params) =>
						qs.stringify(params, { indices: false, skipNulls: true }),
				})
				.then((response) => {
					this.headerFechas = response.data.fechas;
					this.maquinasInstancias = response.data.maquinasInstancias;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.custom-max-header >>> .v-data-table__wrapper {
	max-height: 600px;
}

.preview-container {
	height: calc(100vh - 21.875rem);
}

.vertical-text {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	text-align: left;
	min-height: min-content;
	white-space: nowrap;
}

.text-th {
	font-size: 1.0625rem !important;
}

.today-background-color {
	background-color: #fff59d !important;
}

table th + th {
	border-left: 1px solid #dddddd;
}
table td + td {
	border-left: 1px solid #dddddd;
}
</style>