import axios from "axios";
import moment from "moment";

export default {
  install(Vue) {
    Vue.prototype.$utils = {
      arrays:{
        unique: function (array) {
          function onlyUnique(value, index, self) { 
            return self.indexOf(value) === index;
          }
          return array.filter( onlyUnique ); 
        },
        equals: function (array1, array2) {
          return array1.sort().toString() == array2.sort().toString();
        },
        groupBy: function(array, key, includeObjects = false) {
          var result = [];
          array.forEach(element => {
            var index = result.findIndex(e => {
              return e.key == element[key];
            });
            if(index == -1){
              var obj = {
                key: element[key],
                count: 1,
                items: [],
              }
              if(includeObjects) obj.items.push(element);
              result.push(obj);
            }
            else{
              result[index].count += 1;
              if(includeObjects) result[index].items.push(element);
            }

          });
          return result;
        },
        urlQuery(array,parameter,first = true){
          if (typeof array === 'undefined' || array === null || array.length <= 0) return "";
          if (typeof parameter === 'undefined' || parameter === null || parameter.length <= 0) return "";

          var string = "";
          
          array.forEach(value => {
            if(first) first = false;
            else string += "&";

            string += `${parameter}=${value}`;
          });

          return string;
        }
      },
      strings:{
        formatearCifra( number, decimales = 2){
          let numero = 0;
          if(number != null) numero = number;
          return numero.toLocaleString('en-US', {minimumFractionDigits: decimales, maximumFractionDigits: decimales});
        },
        pluralizar(cadena,cantidad, mayusculas = null){
          if(cadena == null || cantidad == null) return;
          if(isNaN(parseFloat(cantidad)) || cadena.length == 0) return cadena;
          if(mayusculas == null) mayusculas = (/^[A-Z]*$/).test(cadena);
          if(parseFloat(cantidad) == 1){
            return ((/[sS]$/).test(cadena))
              ? cadena.slice(0,-1)
              : mayusculas
                ? cadena.toUpperCase()
                : cadena.toLowerCase();
          }
          else{
            return !((/[sS]$/).test(cadena))
              ? mayusculas
                ? `${cadena}S`.toUpperCase()
                : `${cadena}s`.toLowerCase()
              : cadena;
          }
        },
        removeAt: function (string,pos) {
          var chars = string.split('');
          chars.splice(pos,1);
          return chars.join('');
        },
        truncate: function(string, length){
          if(string.length <= length) return string;
          else{
            return string.substring(0,length) + '...';
          }
        },
        partialMayus: function(string,start,length){
          if(string == null || string.length == 0) return string;
          else if(string.length <= length) return string.toUpperCase();
          else{
            var mayus = string.substring(start,length).toUpperCase();
            return mayus + string.substring(start+length,string.length);
          }
        },
        zeroPrefix(num, digit) {
          if(digit <= 0) return '0';
          if(num > Math.pow(10,digit-1)) return `${num}`;
    
          var zero = '';
          for(var i = 0; i < digit; i++) {
            zero += '0';
          }
          return (zero + num).slice(-digit);
        },
        toTime(seconds){
          var secs = seconds;
          var hours = Math.trunc(secs / 3600);
          secs -= hours * 3600;
          var mins = Math.trunc(secs / 60);
          secs -= mins * 60;
                    
          return (
            this.zeroPrefix(hours, 2) +
            ":" +
            this.zeroPrefix(mins, 2) +
            ":" +
            this.zeroPrefix(secs !== Math.floor(secs) ? Math.floor(secs) : secs, 2)
          );
        },
        isOnlyNumbers: function(string) {
            return /^\d+$/.test(string);
        },
        isNullOrEmpty: function(value){
          return (typeof value == 'undefined' || value == null || value.length <= 0 || value[0] == ' ')
        },
        truncateElipsis(string, maxChars = 27){
          if (!string) return string;
          if (string.length <= maxChars) return string;
      
          const longitudPorcion = Math.floor((maxChars - 3) / 2);
      
          const inicio = string.slice(0, longitudPorcion);
          const fin = string.slice(-longitudPorcion);
      
          return inicio + "..." + fin;
        }
      },
      numbers:{
        validFloat: function (value, defaultValue = 0.0) {
          return isNaN(parseFloat(value)) ? defaultValue : parseFloat(value);
        },
        isFloat(value){
          return !isNaN(parseFloat(value));
        }
      },
      time: {
        noTrim: (num) => ("0"+num).slice(-2),
        secondsToTime(segundos){
          if(segundos == undefined) segundos = 0;
          const hours = Math.floor(moment.duration(segundos,'seconds').asHours());
          const minutes = moment.duration(segundos,'seconds').minutes();
          const seconds = moment.duration(segundos,'seconds').seconds();
          return this.noTrim(hours) + ':' + this.noTrim(minutes) + ':' + this.noTrim(seconds);
        },
      },
      isValid: function (variable){
        return (typeof variable !== 'undefined' && variable !== null);
      },
      axios:{
        downloadFile: function (url,method,filename,mime, data = null) {
          axios({
            url: url,
            method: method,
            responseType: 'blob',
            data: data,
          })
          .then(response => {
            let blob = new Blob([response.data], { type: mime })
            let a = document.createElement("a") 
            let blobURL = URL.createObjectURL(blob)
            a.download = filename
            a.href = blobURL
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
          .catch(error => {
            console.log(error);
          })
        }
      },
      images: {
        blobToBase64(blob){
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              return resolve(reader.result);
            };
            reader.onerror = error => {
              return reject(error);
            };
          })
        },
      },
      colors: {
        hexToRgb: function(hex) {
          // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
          var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
          hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
          });
        
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
          return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          } : null;
        },
        mergeColors(color1, color2, factor = 0.5){
          function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
          }

          return  "#" + componentToHex(parseInt( (factor * color1.r) + ((1 - factor) * color2.r) )) + componentToHex(parseInt( (factor * color1.g) + ((1 - factor) * color2.g) )) + componentToHex(parseInt( (factor * color1.b) + ((1 - factor) * color2.b) ));
        }
      },
      mantenimientos: {
        tiposComprobaciones: [
          {
            text: 'NÚMERICA',
            value: 1
          },
          {
            text: 'SELECCION SIMPLE',
            value: 2
          },
        ],
        tiposMantenimiento: [
          {
            text: 'PREVENTIVO',
            value: 1
          },
          {
            text: 'CORRECTIVO',
            value: 2
          },
          {
            text: 'INSPECCIÓN',
            value: 3
          },
          {
            text: 'SERVICIO',
            value: 4
          },
          {
            text: 'CAMBIO DE MOLDE',
            value: 5
          },
          {
            text: 'CAMBIO DE TROQUEL',
            value: 6
          },
        ],
        tiposTransporte: [
          {
            text: "UTILITARIA",
            value: 1
          },
          {
            text: "MERCANTIL",
            value: 2
          }
        ],
        tiposBascula: [
          {
            text: "PLATO O PLATAFORMA",
            value: 1
          },
          {
            text: "MODULO",
            value: 2
          }
        ],
        tiposAsignacion: [
          {
            text: "PERSONAS",
            value: 1
          },
          {
            text: "PROVEEDOR",
            value: 2
          }
        ],
        defaultOpcionItem: {
          nombre: null,
          errorOpcion: null
        },
        validateKilometraje({ kilometraje, kilometrajeMin }, extemporaneo = false){
          let errorKilometraje = null;
          const parsedKilometraje = parseFloat(kilometraje);
          const parsedKilometrajeMin = parseFloat(kilometrajeMin);
          if(isNaN(parsedKilometraje) || isNaN(parsedKilometrajeMin) ) errorKilometraje = "Invalido";
          else if(parsedKilometraje < 0) errorKilometraje = "No puede ser negativo";
          else if (!extemporaneo && parsedKilometrajeMin > 0 && parsedKilometraje < parsedKilometrajeMin) errorKilometraje = "Debe ser mayor o igual al kilometraje mínimo";
          return errorKilometraje;
        },
        getNombreTipoAsignacion(tipoAsignacion){
          if(tipoAsignacion == 0) tipoAsignacion = 1;
          const asignacion = this.tiposAsignacion.find( (asignacion) => asignacion.value == tipoAsignacion );
          return asignacion?.text || "N/A";
        },
        findNombreProveedor(idMantenimientoProveedor, proveedores = []){
          const proveedor = proveedores.find( (proveedor) => proveedor.idMantenimientoProveedor == idMantenimientoProveedor );
          return proveedor ? this.getNombreProveedor(proveedor) : "N/A";
        },
        getTipoMantenimiento({ tipo }){
          const tipoMant = this.tiposMantenimiento.find( tm => tm.value == tipo );
          return tipoMant?.text || "N/A";
        },
        getNombreMantenimiento({ codigo, nombre }){
          return `[${codigo}] - ${nombre}`.trim();
        },
        esCategoriaEspecifica(maquinas = [], nombreCategoria,idMaquina){
          const maquina = maquinas.find( (maquina) => maquina.idMaquina == idMaquina );
          return maquina?.categoria.includes(nombreCategoria.trim().toUpperCase()) || false;
        },
        getNombreMaquina(maquinas = [], idMaquina){
          const maquina = maquinas.find( (maquina) => maquina.idMaquina == idMaquina );
          return maquina ? `${maquina.marca} - ${maquina.modelo}` : 'N/A';
        },
        getMaquinaCategoria(maquinas = [],idMaquina){
          const maquina = maquinas.find( (maquina) => maquina.idMaquina == idMaquina );
          return maquina?.categoria || "N/A";
        },
        nombreMaquinaInstancia(maquinaIns,nombreEmpresa){
          if(nombreEmpresa.toUpperCase() == 'INDASA'){
            return `No. Económico: ${maquinaIns.nombre}`;
          }
          return maquinaIns.nombre;
        },
        getNombreProveedor({ codigo, nombre }){
          return codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim();
        },
        asignarSubdeptoDefault(subdepartamentos = [], editedItem = {}){
          const subdepto = subdepartamentos.find( (subdepto) => subdepto.nombre.toUpperCase() == "SIN DESIGNAR" );
          if(!subdepto) {
            editedItem.idSubdepartamento = null;
            return;
          }
          editedItem.idSubdepartamento = subdepto.idSubdepartamento;
        },
        getTipoTransportes(id){
          const transporte = this.tiposTransporte.find( (transporte) => transporte.value == id );
          return transporte?.text || 'N/D';
        },
        getTipoBascula(id){
          const bascula = this.tiposBascula.find( (bascula) => bascula.value == id );
          return bascula?.text || 'N/D';
        },
        mapComprobaciones(comprobaciones = []){
          return comprobaciones.map((comprobacion) => {
            if (Array.isArray(comprobacion.recursos)) {
              let idsDocumentos = [];
              let links = [];
    
              comprobacion.recursos.forEach((recurso) => {
                if (recurso.idDocumento != null) {
                  idsDocumentos.push({
                    nombre: recurso.documento,
                    idDocumento: recurso.idDocumento,
                  });
                }
    
                if (recurso.link != null) {
                  links.push({
                    titulo: recurso.tituloLink,
                    url: recurso.link
                  });
                }
              });
    
              comprobacion.recursos = {
                idsDocumentos,
                links,
              };
            }
            comprobacion.numOpciones = comprobacion.opciones.length || 2;
            comprobacion.opciones = comprobacion.opciones.map( (nombre) => ({
              ...this.defaultOpcionItem,
              nombre
            }));
            return comprobacion;
          });
        }
      }
    }
  }
};